var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"visible":_vm.isMyCourseSidebarActive,"size":"xl","hide-footer":"","no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('update:is-my-course-sidebar-active', val); },"shown":function($event){return _vm.getData(_vm.patient_id)}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" My Course ")]},proxy:true}])},[_c('vue-good-table',{ref:"refListTable",attrs:{"columns":_vm.tableColumns,"rows":_vm.courses,"styleClass":"vgt-table condensed","select-options":{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
                    enabled: true,
                    perPage:_vm.pageLength
                }},on:{"update:columns":function($event){_vm.tableColumns=$event},"update:rows":function($event){_vm.courses=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'course_balance')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.balance)+" ")]):(props.column.field == 'expire_date')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.formatDateTh(props.row.expire_date))+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }