<template>
    <div>
      <my-course :is-my-course-sidebar-active.sync="isMyCourseSidebarActive" :patient_id="patient_id" />
    <b-card no-body>
        <div class="m-2 p-1 rd-viva  bg-viva">
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    {{  t('patient') }}
                    <div class="demo-vertical-spacing ml-2">
                        <b-input-group class="input-group-merge">
                           
                            <b-form-input :placeholder="'search_patient'" v-model="searchQuery" />
                            <b-input-group-append is-text>
                                <feather-icon icon="SearchIcon" color="#58ADEB" />
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col
                        cols="12"
                        md="6"
                >
                <div class="d-flex align-items-center justify-content-end">
                    <b-button  
                    :to="{ name: 'patients-create'}"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-success">
                        <feather-icon icon="PlusIcon" />
                        {{  t('add_patient') }}
                    </b-button>

                    <b-media
                        no-body
                        vertical-align="center"
                        class="flex-column flex-md-row"
                      >
                        <b-media-aside>
                          <div class="d-inline-block upload-img border-0">
                            <b-button  
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            class="ml-1"
                            variant="outline-info">
                            <feather-icon icon="FileIcon" />
                            {{ t('import_csv') }}
                                <b-form-file
                                id="fileInput"
                                class="visuallyhidden"
                                accept=".csv"
                                @input="inputFile"
                                >
                                </b-form-file>
                            </b-button>
                          </div>
                        </b-media-aside>
                      </b-media>

                </div>
                    
                </b-col>
            </b-row>
        </div>
        <div class="m-2">
            
            <vue-good-table
            :columns="tableColumns"
            :rows="Data"
            ref="refListTable"
           
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
           
            
            >
            <template
            slot="table-row"
            slot-scope="props"
        >
                <span v-if="props.column.field === 'code_member'">
                  <div class="text-nowrap">{{ props.row.code_member }} </div>
                  <small class="text-nowrap" v-if="props.row.code_old">รหัสเดิม({{ props.row.code_old }}) </small>
                </span>
                <span
                v-else-if="props.column.field === 'first_name'"
                class="text-nowrap"
                
                >   
                    <span class="text-nowrap">{{ props.row.first_name }} {{ props.row.last_name }} </span>
               
                </span>
                <span
                v-else-if="props.column.field === 'group'"
                class="text-nowrap"
                
                >   
                    <span class="text-nowrap">{{ props.row.title_group }}  </span><br />
                    <span class="text-nowrap text-danger" style="font-size:12px;" v-if="props.row.expire_date">
                      ({{ t("expire_date") }} {{ formatDateTh(props.row.expire_date) }}) 
                    </span>
               
                </span>

                
                <span
                v-else-if="props.column.field === 'action'"
                class="text-nowrap"
                >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-success"
                    class="btn-icon rounded-circle" 
                    :to="{ name: 'opd-detail', params:{id:props.row.id}}"
                    :id="`tooltip-target1-${props.row.id}`"

                >
                <feather-icon icon="FilePlusIcon" />
                </b-button>
                <b-tooltip :target="`tooltip-target1-${props.row.id}`" triggers="hover" placement="top">
                    

                    {{  t("create:opd") }}
                </b-tooltip>
                <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-success"
                    class="btn-icon rounded-circle" 
                   
                    :id="`tooltip-target-course-${props.row.id}`"
                    @click="popupCourse(props.row.id)"
                >
                  <feather-icon icon="TrelloIcon" />

                </b-button>
                <b-tooltip :target="`tooltip-target-course-${props.row.id}`" triggers="hover" placement="top">
                   {{  t("Course") }}
                </b-tooltip>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-warning"
                    class="btn-icon rounded-circle" 
                    :to="{ name: 'patients-detail', params:{id:props.row.id}}"
                    :id="`tooltip-target2-${props.row.id}`"

                >
                    <feather-icon icon="ArchiveIcon" />
                </b-button>
                <b-tooltip :target="`tooltip-target2-${props.row.id}`" triggers="hover" placement="top">
                   {{  t("history") }}
                </b-tooltip>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    :to="{ name: 'patients-edit', params:{id:props.row.id}}"
                    :id="`tooltip-target3-${props.row.id}`"

                >
                    <feather-icon icon="Edit3Icon" />
                </b-button>
                <b-tooltip :target="`tooltip-target3-${props.row.id}`" triggers="hover" placement="top">
                  {{ t("edit") }}
                </b-tooltip>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    :id="`tooltip-target4-${props.row.id}`"

                    v-on:click="deleteconfirm(props.row.id,props.row.point_id,props.row.first_name+' '+props.row.last_name)"
                >
                    <feather-icon icon="Trash2Icon" />
                </b-button>
                <b-tooltip :target="`tooltip-target4-${props.row.id}`" triggers="hover" placement="top">
                    {{ t("delete") }}
                </b-tooltip>

                </span>
            </template>

            <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
             Items per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
           
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="Total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
             
              class="mt-1 mb-0"
            
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
            </vue-good-table>
            
        </div>

    </b-card>
  </div>
    
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormFile,BMediaAside
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import usePatientsList from './usePatientsList'
import patientsStoreModule from '../patientsStoreModule'
import { ref,onUnmounted } from '@vue/composition-api'
import { formatDateTh,formatDateToMonthShort,getCommaDecimal } from '@core/utils/filter'
import MyCourse from './MyCourse.vue'

export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormFile,BMediaAside,
        VueGoodTable,
        MyCourse

    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    data(){
      return {
        formatDateTh,formatDateToMonthShort,getCommaDecimal
      }
    },
    mounted() {

    },
    setup() {
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        const PATIENTS_STORE_MODULE_NAME = 'app-patients';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, patientsStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            tableColumns,
            fetchData,
            deleteData,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            currentPage,
            refListTable,
            importCSVData,
            t,
            isMyCourseSidebarActive,
            popupCourse,
            patient_id
        } = usePatientsList();


        
        return {
            userStorage,
            tableColumns,
            fetchData,
            deleteData,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            currentPage,
            refListTable,
            importCSVData,
            t,
            isMyCourseSidebarActive,
            popupCourse,
            patient_id
        }

        
    },methods:{

        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        deleteconfirm(id,point_id,title){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete patient: '+title, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
           this.deleteData({id:id,point_id:point_id})
          }
        })

        },
        inputFile(event){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to import csv: '+event.name, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
            var Data = ref({
                files:event,
                created_by:this.userStorage.id,
                branch_id:this.userStorage.branch_id,
            })
            let formData = new FormData();
            for(const [key, value] of Object.entries(Data.value)) {
              formData.append(key, value);
            }
            this.importCSVData(formData)
          }
        })
        },
    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>