<template>
<b-modal
    :visible="isMyCourseSidebarActive"
    size="xl"
    hide-footer
    no-close-on-backdrop
    @change="(val) => $emit('update:is-my-course-sidebar-active', val)"
    @shown="getData(patient_id)"

>
    <template #modal-title>
      My Course
    </template>
    <vue-good-table
    :columns.sync="tableColumns"
            :rows.sync="courses"
            ref="refListTable"
            styleClass="vgt-table condensed"
            
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
    > 
    <template
            slot="table-row"
            slot-scope="props"
        >
        <span 
            v-if="props.column.field == 'course_balance'"
            class="text-nowrap"
            >
              
                {{ props.row.balance}}
            

        </span>
        <span 
            v-else-if="props.column.field == 'expire_date'"
            class="text-nowrap"
            >
              
            {{ formatDateTh(props.row.expire_date) }}
            

        </span>
       
    </template>
    </vue-good-table>
</b-modal>

</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, computed } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import store from '@/store'
import { VueGoodTable } from 'vue-good-table'
import { formatDateTh,formatDateToMonthShort,getCommaDecimal } from '@core/utils/filter'
export default{
    components: {
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        VueGoodTable
    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    model: {
            prop: "isMyCourseSidebarActive",
            event: "update:is-my-course-sidebar-active",
        },

    props: {
        isMyCourseSidebarActive: {
            type: Boolean,
            required: true,
        },
        patient_id: {
            type: Number,
            required: false,
        },
    },
    data(){
      return {
        formatDateTh,formatDateToMonthShort,getCommaDecimal
      }
    },
    setup(props, { emit }) {
        
        console.log('test-modal');
        const { t } = useI18nUtils()
        const modalShow = ref(props.isMyCourseSidebarActive);
        const userStorage = JSON.parse(localStorage.getItem('userData'));
         const branch_id = userStorage.branch_id;
         const pageLength = ref(10);
        const resetModal = ()=>{
            emit('update:is-my-course-sidebar-active', false)

        }
        const courses = ref([]);
        const tableColumns = ref([{ field: 'title', label: 'ชื่อคอร์ส', sortable: false },
            { field: 'expire_date', label: 'วันหมดอายุ', sortable: false },
            { field: 'course_balance', label: 'คงเหลือ', sortable: false },])
        const refListTable = ref(null);
        const getData = async(patient_id)=>{
            courses.value = []
            await store
            .dispatch(`app-patients/fetchPatientCourseData`, {
              limit:10,
              offset:0,
              
              branch_id:branch_id,
              patient_id:patient_id,
            //  group_id:selected_group.value,
          
              order_by:'id DESC'
          
            })
            .then(response => {
                courses.value = response.data.data;
                console.log('Data.value',response);
             //title,expire_date,qty
            })
            .catch((error) => {
              console.log('error',error);
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching patients group list",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            //app-patients

        }
        return {
            t,
            modalShow,
            resetModal,
            getData,
            tableColumns,
            refListTable,
            pageLength,
            courses
        }
    }
}
</script>